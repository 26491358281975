import { Pipe, PipeTransform } from '@angular/core';
import { TableAction } from '@capturum/ui/api';
import { TodoStudent } from '@features/todo-student/models/todo-student.model';
import { TransferKeys } from '@core/enums/transfer-keys.enum';
import { RoleTypeKeys } from '@core/enums/role-type-keys.enum';

const ADD_CHOICE_KEY = 'add-choice';
const TRANSFER_BUTTONS: RoleTypeKeys[] = [RoleTypeKeys.vo, RoleTypeKeys.voAdmin, RoleTypeKeys.mbo, RoleTypeKeys.mboAdmin];
const NOT_TRANSFER_BUTTONS: RoleTypeKeys[] = [
  RoleTypeKeys.lpTransferpoint, RoleTypeKeys.lpTransferpointAdmin,
  RoleTypeKeys.transfer, RoleTypeKeys.transferAdmin,
];

@Pipe({
  name: 'generateTransferActions',
})
export class GenerateTransferActionsPipe implements PipeTransform {
  public transform(student: TodoStudent, roleType: RoleTypeKeys, actions: TableAction[]): TableAction[] {

    let lpRmcTransferStatus: string;
    let coachTransferStatus: string;

    if (student.transfer_type_municipality_id) {
      lpRmcTransferStatus = student.transfer_type_municipality_last_status_key;
    }

    if (student.transfer_type_transfercoach_id) {
      coachTransferStatus = student.transfer_type_transfercoach_last_status_key;
    }

    if (lpRmcTransferStatus || coachTransferStatus) {
      return this.filterActions(actions, lpRmcTransferStatus, coachTransferStatus, student, roleType);
    }

    return actions.filter(
      action => action.key === ADD_CHOICE_KEY ||
        (action.key === TransferKeys.TransferredToMunicipality ||
          action.key === TransferKeys.FlowCounselorTransferred || action.key === TransferKeys.SpecialTransfer)
        && TRANSFER_BUTTONS.some(buttonType => buttonType === roleType),
    );
  }

  private filterActions(actions: TableAction[], lpRmcTransferStatus: string, coachTransferStatus: string, student: TodoStudent, roleType: RoleTypeKeys): TableAction[] {
    const newActions: TableAction[] = [];

    actions.forEach(action => {
      if (action.key === ADD_CHOICE_KEY) {
        newActions.push(action);
      }

      if (!student.transfer_type_municipality_id &&
        action.key === TransferKeys.TransferredToMunicipality &&
        TRANSFER_BUTTONS.some(buttonType => buttonType === roleType)
      ) {
        newActions.push(action);
      }

      if (lpRmcTransferStatus === TransferKeys.TransferredToMunicipality
        && action.key === TransferKeys.MunicipalityInConsideration
        && NOT_TRANSFER_BUTTONS.some(buttonType => buttonType === roleType)) {
        newActions.push(action);
      }

      if (lpRmcTransferStatus === TransferKeys.MunicipalityInConsideration &&
        (action.key === TransferKeys.MunicipalityHandled || action.key === TransferKeys.MunicipalityDefinite)
        && NOT_TRANSFER_BUTTONS.some(buttonType => buttonType === roleType)) {
        newActions.push(action);
      }

      if (!student.transfer_type_transfercoach_id &&
        action.key === TransferKeys.FlowCounselorTransferred &&
        TRANSFER_BUTTONS.some(buttonType => buttonType === roleType)) {
        newActions.push(action);
      }

      if (coachTransferStatus === TransferKeys.FlowCounselorTransferred
        && action.key === TransferKeys.FlowCounselorInConsideration
        && NOT_TRANSFER_BUTTONS.some(buttonType => buttonType === roleType)) {
        newActions.push(action);
      }

      if (coachTransferStatus === TransferKeys.FlowCounselorInConsideration
        && action.key === TransferKeys.FlowCounselorHandled
        && NOT_TRANSFER_BUTTONS.some(buttonType => buttonType === roleType)) {
        newActions.push(action);
      }

      if (!student.student_has_special_transfer_information
        && action.key === TransferKeys.SpecialTransfer) {
        newActions.push(action);
      }
    });

    return newActions;
  }
}
