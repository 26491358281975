import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'progressBarUnit',
})
export class SchoolProgressBarUnitPipe implements PipeTransform {
  public transform(division: number, divider: number): string {
    return `% - ${division} / ${divider}`;
  }
}
