import { Pipe, PipeTransform } from '@angular/core';
import { RoleTypes } from '@features/news/models/news-messages.model';

@Pipe({
  name: 'showRoleTypes'
})
export class ShowRoleTypesPipe implements PipeTransform {

  public transform(roleTypes: RoleTypes[], locale_id: string, trim: boolean): string {
    const mappedRoleTypes = roleTypes.filter(Boolean).map(roleType => {
      return roleType.translations?.find(x => x.locale_id === locale_id).translation;
    }).join(', ');

    const finalText = mappedRoleTypes.length > 30 && trim ? mappedRoleTypes.substring(0, 30) + '...' : mappedRoleTypes;

    return roleTypes?.length > 0 ? finalText : '-';
  }
}
