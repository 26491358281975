<div class="accordion-item">
  <p-accordion expandIcon="pi pi-fw pi-chevron-down" collapseIcon="pi pi-fw pi-chevron-up">
    <p-accordionTab [header]="rolesData">
      <ng-template pTemplate="content">
        <div class="overflow-auto">
          <div class="table-header row mt-0 mx-0">
            <div class="col">{{ 'intergrip.role.module_id.label' | translate }}</div>
            <div class="col">{{ 'intergrip.role.instance_id.label' | translate }}</div>
            <div class="col" *ngIf="mboRoles.indexOf(roleType?.value[0]?.key) !== -1">{{ sectorCreboField }}</div>
            <div class="col-2"></div>
            <div class="col-2"></div>
          </div>

          <div *ngFor="let role of roleType.value; let last = last let first = first; let index = index"
               class="table-item row mx-0">
            <div class="col">{{ role?.module?.name }}</div>
            <div class="col">{{ role?.tenant }}</div>
            <div class="col" *ngIf="mboRoles.indexOf(roleType?.value[0]?.key) !== -1">
              <span *ngIf="role.crebo_ids?.length > 0 || role.sector_ids?.length || role.mentorpart_available || role.location_ids?.length"
                    class="d-flex align-items-center">
                    <i [ngClass]="fontAwesomeIcon.InfoCircle"
                       (mouseenter)="toggleTooltipPanel($event, role)"
                       (mouseleave)="toggleTooltipPanel($event, undefined)"></i>
                <span class="show">{{ 'intergrip.role.show.label' | translate }}</span>
              </span>
            </div>
            <div class="action-column col-2">
              <i [ngClass]="fontAwesomeIcon.PencilAlt"
                 *ngIf="editRole && (role.key === roleTypeKeys.mboAdmin || role.key === roleTypeKeys.mbo) && editSectorCrebo && role?.module?.key === modules.Inflow"
                 (click)="openRoleEditForm(role);"></i>
            </div>
            <div class="action-column col-2"><i *ngIf="isDeletable" [ngClass]="fontAwesomeIcon.TrashAlt"
                                                (click)="onClick(index, role)"></i></div>
          </div>
        </div>
      </ng-template>
    </p-accordionTab>
  </p-accordion>
</div>
<p-overlayPanel #overlayPanel appendTo="body">
  <div class="sector-tooltip__item-wrapper">
    <ng-container *ngIf="sectors">
      <div class="sector-tooltip__title">
        {{ 'intergrip.role.sector-overlay.label' | translate }}
      </div>
      <div class="sector-tooltip__content" *ngFor="let item of sectors">
        <div class="sector-tooltip__inline">
          {{item}}
        </div>
      </div>
    </ng-container>

    <div *ngIf="sectors?.length > 0 && crebos?.length > 0" class="sector-tooltip__separate-line">
    </div>

    <ng-container *ngIf="crebos">
      <div class="sector-tooltip__title">
        {{ 'intergrip.role.crebo-overlay.label' | translate }}
      </div>
      <div class="sector-tooltip__content">
        <div class="sector-tooltip__inline" *ngFor="let item of crebos">
          {{item}}
        </div>
      </div>
    </ng-container>


    <div *ngIf="sectors?.length > 0 || crebos?.length > 0" class="sector-tooltip__separate-line">
    </div>

    <ng-container *ngIf="locations">
      <div class="sector-tooltip__title">
        {{ 'intergrip.role.location.label' | translate }}
      </div>
      <div class="sector-tooltip__content">
        <div class="sector-tooltip__inline" *ngFor="let item of locations">
          {{item}}
        </div>
      </div>
    </ng-container>

    <div *ngIf="sectors?.length > 0 || crebos?.length > 0 || locations?.length > 0" class="sector-tooltip__separate-line">
    </div>

    <div class="sector-tooltip__title">
      {{ 'intergrip.role.mentorpart_available.label' | translate }}
    </div>
    <div class="sector-tooltip__content">
      <div class="sector-tooltip__inline">
        {{ (mentorpartAvailable ? 'intergrip.toggle.visible' : 'intergrip.toggle.invisible') | translate }}
      </div>
    </div>
  </div>
</p-overlayPanel>
