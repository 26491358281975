import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import { CapturumTemplateDirective } from '@capturum/ui/api';
import { FontAwesomeIcon } from '@core/enums/font-awesome-icon.enum';

@Component({
  selector: 'app-account-detail-item',
  templateUrl: './account-detail-item.component.html',
  styleUrls: ['./account-detail-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AccountDetailItemComponent implements AfterViewInit {
  @Input()
  public label: string;
  @Input()
  public link: string;
  @Input()
  public badgeLabel: string;
  @Output()
  public onClick = new EventEmitter();

  @ContentChildren(CapturumTemplateDirective)
  public templates: QueryList<CapturumTemplateDirective>;

  public labelTemplate: TemplateRef<any>;
  public fontAwesomeIcon = FontAwesomeIcon;

  constructor(private cdr: ChangeDetectorRef) {
  }

  public ngAfterViewInit(): void {
    this.templates.forEach((template) => {
      if (template.getType() === 'label') {
        this.labelTemplate = template.template;
      }
    });

    this.cdr.detectChanges();
  }

  public clickItem(): void {
    this.onClick.emit();
  }

}
