import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'monitorBadgeColor',
})
export class MboCheckMonitorBadgeColorPipe implements PipeTransform {
  public transform(status: string, baseList: any[]): string {
    return baseList.find((item) => item.key === status)?.color;
  }
}
