import { ChangeDetectionStrategy, ChangeDetectorRef, EventEmitter, Output, Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { Subscription } from 'rxjs';
import { FormUtils } from '@core/utils/form.utils';
import {FontAwesomeIcon} from "@core/enums/font-awesome-icon.enum";
import { OverlayPanel } from 'primeng/overlaypanel';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-form-card',
  templateUrl: './form-card.component.html',
  styleUrls: ['./form-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class FormCardComponent implements OnDestroy {
  public fontAwesomeIcon = FontAwesomeIcon;

  // @ts-ignore
  private get submitted(): boolean {
    return this._submitted;
  }

  @Input()
  // @ts-ignore
  private set submitted(value: boolean) {
    this._submitted = value;

    if (value) {
      FormUtils.markAsTouched(this.form);
      this.cdr.detectChanges();
    }
  }

  get form(): UntypedFormGroup {
    return this._form;
  }

  @Input()
  set form(value: UntypedFormGroup) {
    this._form = value;
    this.cdr.detectChanges();

    if (value) {
      this.subscription = this.form.statusChanges.subscribe(() => {
        this.cdr.detectChanges();
      });
    }
  }

  @ViewChild('overlayPanel') public panel: OverlayPanel;
  @Input() public icon: string;
  @Input() public title: string;
  @Input() public showChoiceTooltip: boolean;
  @Input() public model: any;
  @Input() public fields: FormlyFieldConfig[];
  @Input() public options: FormlyFormOptions;
  @Input() public enableDelete: boolean;
  @Output() public deleteClick = new EventEmitter<void>();
  private _submitted: boolean;

  private _form: UntypedFormGroup;
  private subscription: Subscription;

  constructor(private cdr: ChangeDetectorRef, private  translateService: TranslateService) {
  }

  public togglePanel(): void {
      if (this.panel) {
          const el = document.getElementById('tooltip');
          this.panel.show(null, el);
      }
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

    public onDeleteClick(): void {
        if (this.enableDelete) {
            this.deleteClick.emit();
        }
    }
}
