import { TranslateService } from '@ngx-translate/core';
import { Pipe, PipeTransform } from '@angular/core';

enum Status {
  Active = 'general.yes',
  Inactive = 'general.no',
}

@Pipe({
  name: 'isActive',
})
export class IsActivePipe implements PipeTransform {
  constructor(
    private translateService: TranslateService,
  ) { }
  public transform(active: boolean): string {
    return this.translateService.instant(active ? Status.Active : Status.Inactive);
  }
}
