import { Pipe, PipeTransform } from '@angular/core';
import { MapItem } from '@capturum/ui/api';

@Pipe({
  name: 'getLabelByValue',
})
export class GetLabelByValuePipe implements PipeTransform {
  public transform(value: string, values: MapItem[], identifier: string = 'value'): string {
    let result: MapItem;

    values.forEach((item) => {
      if (!result && item[identifier] === value) {
        result = item;
      }
    });

    return result?.label ?? null;
  }
}
