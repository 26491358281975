import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceStringWith',
})

export class ReplaceStringWithPipe implements PipeTransform {

  public transform(replace: string): string {
    return  replace.split(`-`).join(``);
  }
}
