import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'mboCheckUpdateDate'
})
export class MboCheckUpdateDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) { }

  public transform(text: string, updateDate: string | Date, dateFormat: string = 'dd-MM-yyyy'): string {
    return `${text} ${this.datePipe.transform( typeof updateDate === 'object' ? updateDate :  new Date(updateDate.replace(/-/g, '/')), dateFormat)}`;
  }

}
