import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MapItem } from '@capturum/ui/api';
import { TranslateService } from '@ngx-translate/core';
import { FontAwesomeIcon } from '@core/enums/font-awesome-icon.enum';

@Component({
  selector: 'app-mail-tag-list',
  templateUrl: './mail-tag-list.component.html',
  styleUrls: ['./mail-tag-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MailTagListComponent {
  public tags: MapItem[];
  public additionalTags: MapItem[];
  public fontAwesomeIcon = FontAwesomeIcon;

  constructor(private translateService: TranslateService) {
    this.tags = [
      {
        label: this.translateService.instant('intergrip.recipient_name'),
        value: this.translateService.instant('intergrip.recipient_name_tag'),
      },
      {
        label: this.translateService.instant('intergrip.sender_name'),
        value: this.translateService.instant('intergrip.sender_name_tag'),
      },
      {
        label: this.translateService.instant('intergrip.sender_instance'),
        value: this.translateService.instant('intergrip.sender_instance_tag'),
      },
    ];
    this.additionalTags = [
      {
        label: this.translateService.instant('intergrip.student.isn'),
        value: this.translateService.instant('intergrip.isn_tag'),
      },
      {
        label: this.translateService.instant('intergrip.student.student_number'),
        value: this.translateService.instant('intergrip.student_number_tag'),
      },
      {
        label: this.translateService.instant('intergrip.student.school'),
        value: this.translateService.instant('intergrip.school_tag'),
      },
    ];
  }
}
