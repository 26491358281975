import { Pipe, PipeTransform } from '@angular/core';
import { ServicePoint } from '@features/transfer-servicepoints/model/model';
import { NgxPermissionsService } from 'ngx-permissions';
import arrayPath from '../../utils/array-path/array-path.utils';
import { Modules } from '@core/enums/modules.enum';
import { Permissions } from '@core/enums/permissions.enum';
import { TableAction } from '@capturum/ui/api';

@Pipe({
  name: 'servicePoint',
})
export class TransferServicepointActionsPipe implements PipeTransform {
  constructor(private ngxPermissionService: NgxPermissionsService) {
  }

  public transform(servicePoint: ServicePoint, action: TableAction[]): boolean {
    return servicePoint.transfer_service_point_id && (servicePoint.is_regional
      || (!!this.ngxPermissionService.getPermission(arrayPath([Modules.TransferMbo, Permissions.Transfer_service_point, Permissions.Manage_All]))
        || !!this.ngxPermissionService.getPermission(arrayPath([Modules.TransferVo, Permissions.Transfer_service_point, Permissions.Manage_All]))));
  }
}
