import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MessageOfTheDayService {

    public messageOfTheDay = {
        from: '2024-07-15 00:00:00',
        to: '2024-08-16 00:00:00',
        message: `In de zomervakantie is de helpdesk van Intergrip van 15 juli tot 16 augustus per e-mail bereikbaar:  <a href="mailTo:'helpdesk@intergrip.nl'">helpdesk@intergrip.nl</a> Wij wensen u een mooie zomervakantie toe!`,
    };

  private localStorageKey = 'hide_motd_20240715';

  private _shouldShowMOTD = new BehaviorSubject<boolean>(false); // Temporary fix, by default should be true

  constructor() {
    if (new Date(this.messageOfTheDay.from) < new Date() && new Date() < new Date(this.messageOfTheDay.to)) {
      const currentValue = localStorage.getItem(this.localStorageKey);
      this._shouldShowMOTD.next(currentValue !== 'true');
    }
  }

  public shouldShowMOTD(): Observable<boolean> {
    return this._shouldShowMOTD.asObservable();
  }

  public openMOTD(): void {
    localStorage.removeItem(this.localStorageKey);
    this._shouldShowMOTD.next(true);
  }

  public closeMOTD(): void {
    localStorage.setItem(this.localStorageKey, 'true');
    this._shouldShowMOTD.next(false);
  }
}
