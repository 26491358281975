import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'showTrainingStatus'
})
export class ShowTrainingStatusPipe implements PipeTransform {

  public transform(trainingStatus: any, locale_id: string): string {
    return trainingStatus?.translations?.length > 0 ? trainingStatus?.translations?.find(x => x.locale_id === locale_id).translation : '-'
  }
}
