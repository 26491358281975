import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'progressCount',
})
export class ProgressBarProgressCountPipe implements PipeTransform {
  public transform(division: number, divider: number): number {
    return divider > 0 ? Math.round((division / divider) * 10000) / 100 : 0;
  }
}
