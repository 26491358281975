import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
import { BrowserTracing } from '@sentry/tracing';
import { jwtDecode } from 'jwt-decode';
import { AppModule } from './app/app.module';
import { environment } from '@environments/environment';
import Intercom from "@intercom/messenger-js-sdk";
import { hmrBootstrap } from './hmr';
import 'hammerjs';

if (environment.baseUrl.indexOf('intergrip.test') === -1) {
  Sentry.init({
    dsn: "https://03ac9342af45bb80e91b2cfd26f45dd0@o4507106811379712.ingest.de.sentry.io/4507107672260688",
    environment: setEnvName(environment.name),
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      new BrowserTracing({
        tracePropagationTargets: ['localhost', 'https://portaal.intergrip.nl/'],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    tracesSampleRate: 0.002,
  });
}

if (environment.production) {
  enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

if (environment.hmr) {
  if ((module as unknown as any).hot) {
    hmrBootstrap(module, bootstrap);
  } else {
    console.error('HMR is not enabled for webpack-dev-server!');
  }
} else {
  bootstrap().catch(err => console.error(err));
}

function setEnvName(env: string): string {
  switch (env.toLowerCase()) {
    case 'test':
    case 'production':
    case 'acceptance':
      return env;
    default:
      return 'production';
  }
}

try {
    const token = localStorage.getItem('token');
    const jwt = jwtDecode(token) as any;

    Intercom({
        api_base: "https://api-iam.intercom.io",
        app_id: jwt.intercom.app_key,
        user_id: jwt.intercom.user_id,
        name: jwt.name,
        email: jwt.email,
        user_hash: jwt.intercom.hmac,
        user_type: "portal-user",
        module: "Management"
    })
} catch (Error) {
    console.log(Error);
}


