import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
} from '@angular/core';
import { FilterConfig } from '@core/models/filter-config.model';
import { PickListColumn } from '@core/models/pick-list-column';
import { FilterMetadata } from 'primeng/api';
import { InfoTableColumnType } from '@capturum/ui/info-table';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { FontAwesomeIcon } from '@core/enums/font-awesome-icon.enum';

@Component({
  selector: 'app-pick-list-table',
  templateUrl: './pick-list-table.component.html',
  styleUrls: ['./pick-list-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PickListTableComponent implements OnInit, OnDestroy {
  @Input()
  public templates: QueryList<any>;

  @Input()
  public tableData: any[];

  @Input()
  public columns: PickListColumn[];

  @Input()
  public filterConfig: FilterConfig[];

  @Input()
  public isSourceTable = false;

  @Output()
  public onFilter: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public remove: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public add: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public addAll: EventEmitter<void> = new EventEmitter<void>();

  public activeFilters: Record<string, FilterMetadata> = {};
  public DataTableType: typeof InfoTableColumnType = InfoTableColumnType;
  public fontAwesomeIcon = FontAwesomeIcon;

  private filterAction = new Subject<Record<string, FilterMetadata>>();
  private subSink = new SubSink();

  public ngOnInit(): void {
    this.subSink.add(
      this.filterAction.asObservable()
        .pipe(
          debounceTime(300),
        )
        .subscribe((filters) => {
          this.onFilter.emit(filters);
        }),
    );
  }

  public ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  /**
   * Filter on the dataset
   *
   * @param event: { value: any, field: string, matchMode: string }
   * @return void
   */
  public filter(event: { value: any, field: string, matchMode: string }): void {
    const { value, matchMode }: { value: string, matchMode: string; } = event;
    this.activeFilters[event.field] = { value, matchMode };

    this.filterAction.next(this.activeFilters);
  }

  /**
   * Reset the filters
   *
   * @return void
   */
  public reset(): void {
    this.activeFilters = {};
    this.onFilter.emit(this.activeFilters);
  }
}
