import { Pipe, PipeTransform } from '@angular/core';
import { Mentor } from '@features/mentors/models/Mentors.model';
import { ColumnOption } from '@capturum/ui/info-table/base/column-option.model';
import { SchoolClass } from '@features/school-class/models/school-class.model';
import { Field } from '@shared/field/field.enum';

@Pipe({
  name: 'checkAllClasses'
})
export class CheckAllClassesPipe implements PipeTransform {
  public transform(mentor: Mentor, tableColumnOptions: ColumnOption[], grade: string | number): boolean {
    const columnOptions: ColumnOption[] =
      tableColumnOptions.filter(column => column.field !== Field.UserName && column.field !== Field.AllClasses);
    let schoolClasses: SchoolClass[] = mentor.schoolClasses;

    if (typeof grade === 'number') {
      schoolClasses = mentor.schoolClasses.filter(schoolClass => schoolClass.grade === grade);
    }

    return schoolClasses?.length === columnOptions?.length;
  }
}
