import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fromStringToJson',
})
export class FromStringToJsonPipe implements PipeTransform {
  public transform(list: string, value: string = ''): string | any {
    try {
      const parsedJson = JSON.parse(list);
      return value ? parsedJson[value] : parsedJson;
    } catch (e) {
      return null;
    }
  }
}
