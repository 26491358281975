import { Pipe, PipeTransform } from '@angular/core';
import { TableAction } from '@capturum/ui/api';
import { UserService } from '@features/user/services/user.service';
import { UserModel } from '@features/user/models/user-model.interface';

@Pipe({
  name: 'filterUserRowActions'
})
export class FilterUserRowActionsPipe implements PipeTransform {
  constructor(private userService: UserService) {}

  public transform(actions: TableAction[], row: UserModel): TableAction[] {
    if (!this.userService.hasHigherRoleRank(row.currentRoleType, row.roles)) {
      actions = actions.filter(action => action.key !== 'delete');
    }

    return actions;
  }
}
