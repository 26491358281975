import { Pipe, PipeTransform } from '@angular/core';
import { TableAction } from '@capturum/ui/api';
import { UserButtonKeys } from '@core/enums/user-button-keys.enum';
import { UserModel } from '@features/user/models/user-model.interface';

@Pipe({
  name: 'generateUserButtons'
})
export class GenerateUserButtonsPipe implements PipeTransform {
  public transform(user: UserModel, isImpersonating: boolean = false, actions: TableAction[]): TableAction[] {
    if (!user.roles_amount || user.roles_amount === 0 || isImpersonating) {
      actions = actions.filter(action => action.key !== UserButtonKeys.LoginAs);
    }

    if (!user.can_be_deleted) {
      actions = actions.filter(action => action.key !== UserButtonKeys.Delete);
    }

    return actions;
  }
}
