import { Pipe, PipeTransform } from '@angular/core';
import { TableAction } from '@capturum/ui/api';
import { CreboItem } from '@features/trainings/models/crebo.model';

@Pipe({
  name: 'creboActions',
})
export class CreboActionsPipe implements PipeTransform {
  public transform(creboItem: CreboItem, actions: TableAction[]): TableAction[] {
    if (creboItem.process_id) {
        return [];
    }
    return actions;
  }
}
