export enum TransferKeys {
  TransferredToMunicipality = 'transferred-to-municipality',
  FlowCounselorTransferred = 'flow-counselor-transferred',
  MunicipalityInConsideration = 'municipality-in-consideration',
  FlowCounselorInConsideration = 'flow-counselor-in-consideration',
  MunicipalityHandled = 'municipality-handled',
  FlowCounselorHandled = 'flow-counselor-handled',
  MunicipalityDefinite = 'municipality-definite',
  FlowCounselorDefinice = 'flow-counselor-definite',
  SpecialTransfer = 'special-transfer',
  RollBack = 'roll-back',
}
