import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'showTrainingList'
})
export class ShowTrainingListPipe implements PipeTransform {
  public transform(data: {choiceable: {name: string}}[]): string {
    if (data?.length === 0) {
      return '-';
    }
    return data.map(item => item.choiceable.name).join(', ');
  }
}

