import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isInArray',
})
export class IsInArrayPipe implements PipeTransform {
  public transform(itemId: string, target: any[], targetId?: string): boolean {
    const item = target.find((targetItem) => targetId ? targetItem[targetId] === itemId : targetItem === itemId);
    return !!item;
  }
}
