import { Pipe, PipeTransform } from '@angular/core';
import { Modules } from '@features/news/models/news-messages.model';

@Pipe({
  name: 'showModules'
})
export class ShowModulesPipe implements PipeTransform {

  public transform(modules: Modules[], trim: boolean): string {
    const mappedModules = modules.map(module => module.name).join(', ');

    const finalText = mappedModules.length > 30 && trim ? mappedModules.substring(0, 30) + '...' : mappedModules;

    return modules?.length > 0 ? finalText : '-';
  }
}
