import { TransferType } from '@core/enums/transfer-type.enum';
import { Transfer } from '@features/todo-student/models/transfer.model';
import { Pipe, PipeTransform } from '@angular/core';
import { TodoStudent } from '@features/todo-student/models/todo-student.model';

@Pipe({
  name: 'transferType'
})
export class TransferTypePipe implements PipeTransform {
  public transform(student: TodoStudent, type: TransferType): string {
    const transferTypeStudentYear: Transfer = student.currentStudentYear?.transfers?.find(
      choiceStudentYear => choiceStudentYear.transfer_type.value === type
    );

    return transferTypeStudentYear ? transferTypeStudentYear.latestTransferStatus.signupStatus.name : '-';
  }
}
