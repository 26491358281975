import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SeverityEnum } from '@core/enums/severity.enum';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent {
  @Input()
  public icon: string;
  @Input()
  public label: string;
  @Input()
  public severity: SeverityEnum = SeverityEnum.Success;
  @Input()
  public color?: string;
}
