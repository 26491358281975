<cap-card>
    <ng-template capTemplate="header">
        <span class="header">
            <i *ngIf="icon" [class]="icon"></i><span class="card__title">{{ title }}</span>
            <i *ngIf="showChoiceTooltip" class="fa fa-info-circle info-button" id="tooltip" (click)="togglePanel()"></i>
        </span>
    </ng-template>

    <ng-template capTemplate="content">
        <div class="card-content-wrapper" [class.form-card__form--disabled]="form && form.disabled">

            <formly-form [model]="model" [fields]="fields" [options]="options" [form]="form"></formly-form>
            <div *ngIf="fields && enableDelete" class="actions cursor"><i (click)="onDeleteClick()" [class]="fontAwesomeIcon.Trash"></i></div>
        </div>

        <ng-content></ng-content>
    </ng-template>
</cap-card>

<p-overlayPanel #overlayPanel appendTo="body" styleClass="popup">
    <div class="popup-wrapper">
        <div class="text-block">
            {{ 'transfer-vo.todo-student.info-part-1.label' | translate }}
        </div>

        <div class="text-block">
            <ul class="unordered-list">
                <li>Havo eigen school*</li>
                <li>Havo andere school*</li>
                <li>VMBO eigen school*</li>
                <li>VMBO andere school*</li>
                <li>Particulier onderwijs*</li>
                <li>VAVO</li>
                <li>Overig</li>
            </ul>
        </div>

        <div class="cursive-info">
            {{ 'transfer-vo.todo-student.info-part-2.label' | translate }}
        </div>

        <div class="text-block">
            {{ 'transfer-vo.todo-student.info-part-3.label' | translate }}
        </div>

        <div class="text-block">
            <ul class="unordered-list">
                <li>In zorginstelling</li>
                <li>School buitenland/Uitwisselingsprogramma</li>
                <li>Wil / Gaat werken</li>
                <li>Defensie **</li>
                <li>Politie **</li>
            </ul>
        </div>

        <div class="cursive-info">
            {{ 'transfer-vo.todo-student.info-part-4.label' | translate }}
        </div>

        <div class="text-block">
            {{ 'transfer-vo.todo-student.info-part-5.label' | translate }}
        </div>
    </div>
</p-overlayPanel>
