import { Pipe, PipeTransform } from '@angular/core';
import { ChoiceStudentYear, TodoStudent } from '@features/todo-student/models/todo-student.model';
import { ChoiceType } from '@core/enums/choice-type.enum';
@Pipe({
  name: 'trainingInstance',
})
export class TrainingInstancePipe implements PipeTransform {
  public transform(student: TodoStudent): string {
    const bestChoiceStudentYear: ChoiceStudentYear = student.currentStudentYear?.choiceStudentYears?.find(
      choiceStudentYear => choiceStudentYear.bestChoice === true,
    );
    if (bestChoiceStudentYear?.choiceable_type !== ChoiceType.Other && bestChoiceStudentYear?.choiceable?.name) {
      return bestChoiceStudentYear.choiceable.name;
    }
    return '-';
  }
}
