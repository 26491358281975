import { Pipe, PipeTransform } from '@angular/core';
import { VoUserRoleUserModel } from '@features/vo-users/models/vo-user-roles.model';

@Pipe({
  name: 'isAdminVoUser',
})
export class IsAdminVoUserPipe implements PipeTransform {
  public transform(roles: VoUserRoleUserModel[]): boolean {
    return roles && roles.length && roles.some((role) => role.role?.roleable_type?.indexOf('AdminRole') > -1 && role.role?.roleable_type?.indexOf('RegionAdminRole') < 0) || false;
  }
}
