import { Pipe, PipeTransform } from '@angular/core';
import { ChoiceStudentYear, TodoStudent } from '@features/todo-student/models/todo-student.model';

@Pipe({
  name: 'choiceStatus'
})
export class ChoiceStatusPipe implements PipeTransform {
  public transform(student: TodoStudent): string {
    const bestChoiceStudentYear: ChoiceStudentYear[] = student.currentStudentYear?.choiceStudentYears?.filter(
      choiceStudentYear => choiceStudentYear.bestChoice === true
    );
    return bestChoiceStudentYear?.length > 0 ? bestChoiceStudentYear[0].latestChoiceUpdate.signupStatus.name : '-';
  }
}
