import { BUILD_TIMESTAMP } from './timestamp';

const packageJson = require('../../package.json');

export const environment = {
  appName: 'Intergrip 2.0',
  production: false,
  hmr: false,
  name: 'test',
  baseUrl: 'https://api.test.intergrip.nl/api',
  sentryUrl: null,
  webSocketKey: '43c0d31de09079c15aed',
  databaseName: 'emendis.complete',
  version: packageJson.version + '.' + BUILD_TIMESTAMP,
  redirectAfterLogin: '/'
};
