import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FontAwesomeIcon } from '@core/enums/font-awesome-icon.enum';
import { delay, filter, map } from 'rxjs/operators';
import { NavMenuItem } from '@core/models/nav-menu-item.model';
import { Modules } from '@core/enums/modules.enum';
import { Observable } from 'rxjs';
import { MenuItemService } from '@shared/services/menu-item.service';

@Component({
  selector: 'app-export-item',
  templateUrl: './export-item.component.html',
  styleUrls: ['./export-item.component.scss'],
})

export class ExportItemComponent implements OnInit {
  public downloadIcon: FontAwesomeIcon = FontAwesomeIcon.Download;
  @Input() public icon: FontAwesomeIcon;

  @Input()
  public set buttonIcon(icon: FontAwesomeIcon) {
    if (icon) {
      this.downloadIcon = icon;
    }
  }

  @Input() public title: string;
  @Input() public subtitle?: string;
  @Input() public exportButtonText: string;
  @Input() public permissions: string|string[];
  @Output()
  public callBack: EventEmitter<any> = new EventEmitter<any>();
  public activeMenuItemKey$: Observable<string>;

  constructor(private menuItemService: MenuItemService,
  ) {
  }

  public ngOnInit(): void {
    this.activeMenuItemKey$ = this.menuItemService.getActiveMenuItem().pipe(
      filter(Boolean),
      map((menuItem: NavMenuItem) => {
        return `module-${menuItem.key ?? Modules.Intergrip}`;
      }), delay(1));
  }

  public onClick(): void {
    this.callBack.emit();
  }
}
