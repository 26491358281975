import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-table-label',
  templateUrl: './table-label.component.html',
  styleUrls: ['./table-label.component.scss'],
})
export class TableLabelComponent {
  @Input()
  public title: string;
  @Input()
  public color?: string;
}
