import { Pipe, PipeTransform } from '@angular/core';
import { TableAction } from '@capturum/ui/api';
import { DiscussionStudentYearItem } from '@features/discussion-round/models/model';

const HANDLE = 'handle';
const DELETE = 'delete';

@Pipe({
  name: 'generateStudentToDiscussActions',
})
export class GenerateStudentToDiscussActionsPipe implements PipeTransform {
  public transform(student: DiscussionStudentYearItem, actions: TableAction[]): TableAction[] {
    let newActions = actions;
    if (student.handled) {
      newActions = newActions.filter((item) => item.key !== HANDLE);
    }

    if (!student.discussion_round_is_active) {
      newActions = newActions.filter((item) => item.key !== DELETE);
    }

    return newActions;
  }

}
