import { Pipe, PipeTransform } from '@angular/core';
import { PronexusMunicipalitiesItem } from '@features/pronexus/models/pronexus.model';

@Pipe({
  name: 'showMunicipalities'
})
export class ShowMunicipalitiesPipe implements PipeTransform {
  public transform(pronexusMunicipalitiesItems: PronexusMunicipalitiesItem[]): string {
    if (pronexusMunicipalitiesItems?.length === 0 ) {
      return '-';
    }
    if (pronexusMunicipalitiesItems?.length > 3 ) {
      return `${pronexusMunicipalitiesItems.slice(0, 3).map(pronexusMunicipalitiesItem => pronexusMunicipalitiesItem.name).join(', ')}...`;
    }
    return pronexusMunicipalitiesItems.map(pronexusMunicipalitiesItem => pronexusMunicipalitiesItem.name).join(', ');
  }
}
