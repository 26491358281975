import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormattedRole } from '@features/role/models/role.model';
import { OverlayPanel } from 'primeng/overlaypanel';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@features/user/services/user.service';
import { RoleTypeKeys } from '@core/enums/role-type-keys.enum';
import { MapItem } from '@capturum/ui/api';
import { Modules } from '@core/enums/modules.enum';
import { FontAwesomeIcon } from '@core/enums/font-awesome-icon.enum';

@Component({
  selector: 'app-role-accordions',
  templateUrl: './role-accordions.component.html',
  styleUrls: ['./role-accordions.component.scss'],
})
export class RoleAccordionsComponent implements OnInit {
  @ViewChild('overlayPanel')
  public overlaypanel: OverlayPanel;
  public mboRoles = [RoleTypeKeys.mboAdmin, RoleTypeKeys.mbo];
  @ViewChild('localePanel')
  public localPanel: OverlayPanel;

  public sectorData: string;
  public rolesData: string;
  public roleTypeKeys: typeof RoleTypeKeys = RoleTypeKeys;
  public propertyValues: MapItem[];
  public sectors: string[];
  public locations: string[];
  public crebos: string[];
  public mentorpartAvailable: boolean;
  public sectorCreboField: string;
  public modules: typeof Modules = Modules;
  public fontAwesomeIcon = FontAwesomeIcon;

  @Output()
  public deleteRole = new EventEmitter<{ index: number, role: FormattedRole, noRoles: boolean }>();

  @Output()
  public onEditRoleForm = new EventEmitter<FormattedRole>();

  @Input()
  public roleType: { key: string, value: FormattedRole[] };

  @Input()
  public isDeletable: boolean;

  @Input()
  public editRole: boolean;

  @Input()
  public editSectorCrebo: boolean;

  constructor(
    public translateService: TranslateService,
    public userService: UserService,
  ) {
  }

  public onClick(index: number, role: FormattedRole): void {
    this.roleType.value = this.roleType.value.filter(roleValue => roleValue !== role);
    this.deleteRole.emit({ index, role, noRoles: this.roleType.value.length === 0 });
  }

  public toggleTooltipPanel(event: MouseEvent, role: FormattedRole): void {
    if (role) {
      this.crebos = role.crebo_ids && role.crebo_ids.length > 0 ? role.crebo_ids : null;
      this.locations = role.location_ids && role.location_ids.length > 0 ? role.location_ids : null;
      this.sectors = role.sector_ids && role.sector_ids.length > 0 ? role.sector_ids.map((sectorItem: string) =>
        this.propertyValues.find((propValue: MapItem) =>
          propValue.value === sectorItem).label) : null;
      this.mentorpartAvailable = role.mentorpart_available;

      if (!this.overlaypanel.target) {
        this.overlaypanel.show(event);
      }
    } else {
      this.overlaypanel.hide();
    }
  }

  public ngOnInit(): void {
    this.userService.propertyValues$.subscribe((propertyValues: MapItem[]) => {
      this.propertyValues = propertyValues;
    });

    this.rolesData = `${this.roleType?.key} (${this.roleType?.value?.length} ${this.roleType?.value?.length > 1 ?
      this.translateService.instant('intergrip.role.plural') :
      this.translateService.instant('intergrip.role.entity_name')})`;
    this.sectorCreboField = this.translateService.instant(`intergrip.role.filter.label`);
  }

  public openRoleEditForm(role: FormattedRole): void {
    this.onEditRoleForm.emit(role);
  }
}
