import { Pipe, PipeTransform } from '@angular/core';
import { ChoiceStudentYear, TodoStudent } from '@features/todo-student/models/todo-student.model';

@Pipe({
  name: 'choiceInstance',
})
export class ChoicesInstancePipe implements PipeTransform {
  public transform(student: TodoStudent): string {
    const bestChoiceStudentYear: ChoiceStudentYear = student.currentStudentYear?.choiceStudentYears?.find(
      choiceStudentYear => choiceStudentYear.bestChoice === true
    );

    if (bestChoiceStudentYear?.choiceable?.school?.name) {
      return bestChoiceStudentYear.choiceable.school.name;
    }
    if (bestChoiceStudentYear?.choiceable?.name) {
      return bestChoiceStudentYear.choiceable.name;
    }
    return '-';
  }
}
