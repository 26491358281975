export enum SeverityEnum {
  Error = 'error',
  Success = 'success',
  Warning = 'warning',
  Danger = 'danger',
  Disabled = 'disabled',
  Default = 'default',
  DefaultBlue = 'default-blue',
  Info = 'info',
  Purple = 'purple'
}
