import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  template: `
    <app-json-viewer [json]="data"></app-json-viewer>
  `,
  styles: [
  ]
})
export class JsonViewerContainerComponent implements OnInit {
  public data: any;
  constructor(
    private dialogConfig: DynamicDialogConfig,
  ) { }

  public ngOnInit(): void {
    const json = this.dialogConfig.data?.json;
    if (json) {
      this.data = JSON.parse(json);
    }
  }

}
