<app-activity-log-timeline *ngIf="showData" [items]="modelLogs"
                        [translationKeyPrefix]="'intergrip'"
                        [emptyResultsTranslationKey]="'intergrip.activity-log.no-history'">
  <ng-template capTemplate="header" let-item>
    {{ 'intergrip.activity-log.' + item.action + '-by.label' | translate : {
    entity: item.entity_key ? (('intergrip.' + (item.entity_key | lowercase) + '.entity_name' | translate) | capitalizeFirst) : ('intergrip.activity-log.entity' | translate),
    user: item.user.name
    }
    }}

    <div class="timeline__created">
      {{ item.created_at | dateParser: 'dd - MM - yyyy @ HH:mm' }}
    </div>
  </ng-template>
</app-activity-log-timeline>
<div *ngIf="loading">
  <app-loader></app-loader>
</div>
<div class="user-history__load-more" (click)="loadData(currentUserId, historyLogPage + 1)"
     *ngIf="(totalModelLogs && totalModelLogs > itemsPerPage * historyLogPage) && !loading">
  {{ 'intergrip.activity-log.load-more' | translate }}
</div>
