import { Component, Input } from '@angular/core';
import { InScreenMenuItem } from '@core/models/in-screen-menu-item.model';
import { Router } from '@angular/router';
import { FontAwesomeIcon } from '@core/enums/font-awesome-icon.enum';

@Component({
  selector: 'app-in-screen-sub-menu',
  templateUrl: './in-screen-sub-menu.component.html',
  styleUrls: ['./in-screen-sub-menu.component.scss'],
})
export class InScreenSubMenuComponent {
  @Input()
  public menuItems: InScreenMenuItem[];
  public fontAwesomeIcon = FontAwesomeIcon;

  constructor(private router: Router) {
  }

  public onItemClick(item: InScreenMenuItem): void {
    if (!item.locked) {
      this.router.navigate([item.route]);
    }
  }
}
