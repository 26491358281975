import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayPath',
})
export class ArrayPathPipe implements PipeTransform {
  public transform(list: string[], separator: string = '.'): string {
    if (!list ||  list.length === 0) {
      return '';
    }
    return list.join(separator);
  }
}
