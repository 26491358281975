import { Pipe, PipeTransform } from '@angular/core';
import { TransferKeys } from '@core/enums/transfer-keys.enum';

@Pipe({
  name: 'todoStudentIconColor',
})
export class TodoStudentIconColorPipe implements PipeTransform {
  public transform(type: string): { [key: string]: boolean } {
    switch (type) {
      case TransferKeys.TransferredToMunicipality:
      case TransferKeys.FlowCounselorTransferred:
        return { orange: true };
      case TransferKeys.MunicipalityDefinite:
      case TransferKeys.MunicipalityHandled:
      case TransferKeys.FlowCounselorHandled:
        return { green: true };
    }
    return null;
  }
}
