import { Pipe, PipeTransform } from '@angular/core';
import { Modules } from '@core/enums/modules.enum';
import { RoleTypeKeys } from '@core/enums/role-type-keys.enum';
import { Module } from '@features/module/models/module.model';

@Pipe({
  name: 'roleTranslation',
})
export class RoleTranslationPipe implements PipeTransform {

  public transform(role: RoleTypeKeys, module: Module, long: boolean): string {
    const roles = [RoleTypeKeys.vo, RoleTypeKeys.voAdmin];
    let description = long ? module?.description : module?.short_description;
    const short = long ? `` : `short-`;
    if (roles.includes(role)) {
      switch (module?.key) {
        case Modules.Lobdossier:
          description = `intergrip.my-products.${short}description.portfolioVO`;
          break;
        case Modules.LobVoorlichtingen:
          description = `intergrip.my-products.${short}description.voorlichtingen`;
          break;
      }
    }

    return description;
  }
}
