import { Component, HostBinding, Input, OnChanges } from '@angular/core';

interface Segment {
  key: string;
  value: any;
  type: undefined | string;
  description: string;
  expanded: boolean;
}
enum SegmentType {
  Number = 'number',
  Boolean = 'boolean',
  Function = 'function',
  String = 'string',
  Array = 'array',
  Undefined = 'undefined',
  Date = 'date',
  Object = 'object',
  Null = 'null'
}

@Component({
  selector: 'app-json-viewer',
  templateUrl: './json-viewer.component.html',
  styleUrls: ['./json-viewer.component.scss']
})
export class JsonViewerComponent implements OnChanges {
  @HostBinding('class') public class = 'intergrip-json-viewer__wrapper';

  @Input() public json: any;
  @Input() public expanded = true;
  @Input() public depth = -1;

  @Input() public currentDepth = -1;

  public segments: Segment[] = [];

  public ngOnChanges(): void {
    this.viewJson();
  }

  public viewJson(): void {
    this.segments = [];

    // remove cycles
    this.json = this.decycle(this.json);

    this.currentDepth++;

    if (typeof this.json === SegmentType.Object) {
      Object.keys(this.json).forEach(key => {
        this.segments.push(this.parseKeyValue(key, this.json[key]));
      });
    } else {
      this.segments.push(this.parseKeyValue(`(${typeof this.json})`, this.json));
    }
  }

  public isExpandable(segment: Segment): boolean {
    return segment.type === SegmentType.Object || segment.type === SegmentType.Array;
  }

  public toggle(segment: Segment): void {
    if (this.isExpandable(segment)) {
      segment.expanded = !segment.expanded;
    }
  }

  private parseKeyValue(key: string, value: any): Segment {
    const segment: Segment = {
      key,
      value,
      type: undefined,
      description: `${value}`,
      expanded: this.isExpanded()
    };

    switch (typeof segment.value) {
      case 'number': {
        segment.type = SegmentType.Number;
        break;
      }
      case 'boolean': {
        segment.type = SegmentType.Boolean;
        break;
      }
      case 'function': {
        segment.type = SegmentType.Function;
        break;
      }
      case 'string': {
        segment.type = SegmentType.String;
        segment.description = `"${segment.value}"`;
        break;
      }
      case 'undefined': {
        segment.type = SegmentType.Undefined;
        segment.description = SegmentType.Undefined;
        break;
      }
      case 'object': {
        if (segment.value === null) {
          segment.type = SegmentType.Null;
          segment.description = SegmentType.Null;
        } else if (Array.isArray(segment.value)) {
          segment.type = SegmentType.Array;
          segment.description = `Array[${segment.value.length}] ${JSON.stringify(segment.value)}`;
        } else if (segment.value instanceof Date) {
          segment.type = SegmentType.Date;
        } else {
          segment.type = SegmentType.Object;
          segment.description = `Object ${JSON.stringify(segment.value)}`;
        }
        break;
      }
    }

    return segment;
  }

  private isExpanded(): boolean {
    return (
      this.expanded &&
      !(this.depth > -1 && this.currentDepth >= this.depth)
    );
  }

  // https://github.com/douglascrockford/JSON-js/blob/master/cycle.js
  private decycle(object: any): any {
    const objects = new WeakMap();
    return (function derez(value: any, path: string): any {
      let old_path;
      let new_path: any;

      if (
        value
        && typeof value === 'object'
        && !(value instanceof Date)
      ) {
        old_path = objects.get(value);
        if (old_path !== undefined) {
          return { $ref: old_path };
        }
        objects.set(value, path);

        if (Array.isArray(value)) {
          new_path = [];
          value.forEach((element, i) => {
            new_path[i] = derez(element, `${path} [${i}]`);
          });
        } else {
          new_path = {};
          Object.keys(value).forEach((name) => {
            new_path[name] = derez(value[name], `${path}[${JSON.stringify(name)}]`);
          });
        }
        return new_path;
      }
      return value;
    }(object, '$'));
  }
}
