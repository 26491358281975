import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cutText',
})
export class CutTextPipe implements PipeTransform {
  public transform(value: string, length: number = 50): string {
    return value?.length > length ? `${value.substring(0, length)}...` : value;
  }

}
