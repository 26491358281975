import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textToAsterisk',
})
export class TextToAsteriskPipe implements PipeTransform {
  public transform(item: string | number, numbersToShow: number = 3, placeholder: string = '-'): string {
    if (item) {
      return `${'*'.repeat(`${item}`.length - numbersToShow)}${`${item}`.substr(`${item}`.length - numbersToShow)}`;
    }

    return placeholder;
  }
}
